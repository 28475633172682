.container-landding {
  width: 100% !important;
  overflow: hidden !important;
  // overflow-y: auto !important;
  height: 100vh;
  padding: 0px 7%;
}
.landing-bg {
  margin-top: 3px;
  width: 100% !important;
  height: 70%;
  // margin: 10% 7%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.landing-content {
  color: red !important;
  // position: relative;
  align-items: center;
  // margin-bottom: 150px;
  // width: 98%;
  // margin-top: 20px;
  // padding-top: 30px;

  position: absolute;
  background: white;
  width: 101%;
  // margin-left: 12px;
  height: 210px;
  padding: 0px !important;
  // margin-left: 12px;
  bottom: 0%;
  margin-left: -1px;
  opacity: 1;

  .content {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 20%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .slider-title {
    // height: 94px;
    // width: 555px;
    color: #000000;
    font-size: 40px;
    letter-spacing: -1.67px;
    line-height: 47px;
    padding: 0px 0px;
  }

  .slide-show {
    // position: absolute;
    width: 100%;
    bottom: 0%;
    padding: 0px !important;
    // left: 5%;
    // margin-top: -40px;
    margin-left: -5px;
    top: 0;
  }

  .control-dots {
    position: absolute;
    right: -54%;
    margin: 0px;
    top: 8%;
    height: 120px;
    transform: rotate(-90deg);

    .dot {
      background: rgb(0, 0, 0);
      width: 7px;
      height: 7px;
    }
  }
  .slick-dots li button:before {
    font-size: 10px !important;
  }
}

.link-paid {
  .dot {
    margin: 0 5px !important;
  }
}

.main-title {
  font-size: 18px;
  letter-spacing: -0.97px;
  line-height: 22px;
  padding-left: 0px;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: left;
}

.sub-title {
  font-size: 13px;
  letter-spacing: -0.38px;
  line-height: 18px;
  padding-left: 5px;
  padding-top: 10px;
  text-align: left;
  width: 70%;
  // line-height: 8px;
}

.reward-ico {
  height: 40px;
  width: 33px;
  margin-top: 10px;
  margin-left: 0px;
  border-radius: 4px;
  margin-right: 10px;
  background: linear-gradient(155.27deg, #4b64ff 0%, #3c4db6 100%);
  img {
    color: white;
    // background: red;
    padding: 2px;
    padding-top: 3px;
    filter: brightness(0) invert(1);
    height: 40px;
    width: 32px;
  }
}

.accept-group {
  position: absolute;
  // bottom: 30px;
  margin: auto;
  width: 100%;
  padding-top: 0px !important;
}

.sub-title-ssn-update {
  padding: 10px 0px 10px;
  font-size: 15px !important;
  letter-spacing: -0.93px;
  line-height: 18px;
}

.img-comeback {
  margin-top: 28px;
  img {
    width: 30px;
    height: 30px;
  }
}

.login-btn {
  margin-top: 20px;
}

.modal-container {
  height: 100%;
  position: relative;
}

.create-account-btn-group {
  // position: absolute;
  // bottom: 12%;
  width: 100%;
  display: block;
  justify-content: center;
  margin-top: 100px;

  .btn-create-account {
    padding-top: 0px !important;
  }
}

.img-logo {
  margin-top: 20px;
}

.step-title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 19px;
}

.otp-info {
  font-size: 16px;
  letter-spacing: -0.67px;
  line-height: 19px;
}

.login-group-btn {
  // position: absolute;
  // bottom: 10px;
  // width: calc(100% - 14%) !important;
  // display: flex;
  // justify-content: center;
  // // display: flex;
  // z-index: 2;
}

.w-btn-signup-responsive {
  width: 100px !important;
  font-size: 16px !important;
  letter-spacing: -0.8px;
  line-height: 19px;
}
.login-group-btn {
  .w-btn-signup-responsive {
    width: 100px !important;
    &:focus,
    &:hover {
      width: 100px !important;
    }
  }
}

.side-group {
  // padding-top: 100px !important;
  height: 90vh !important;
}

.reward-grp {
  // margin-top: 60vh;
}

// .image-landing-viewer {
//   height: 100% !important;
//   z-index: 1;
// }

.carousel-slider {
  width: 110% !important;
}

// .modal-dialog {
//   height: 98%;
// }
// .modal-content,
// .modal-view,
// .modal-container,
// .modal-item {
//   height: 100% !important;
// }

.modal {
  .modal-dialog {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
  }
}

.modal-min-full-screen {
  .modal-content,
  .modal-view {
    min-height: calc(100vh - (0.5rem * 2));
  }
}

.modal-info {
  top: unset !important;
}

.min-full-screen {
  min-height: calc(100vh - (0.5rem * 2) - 87px);
}

.signup-stp3 {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.step-info {
  font-size: 16px !important;
  letter-spacing: -0.67px;
  line-height: 19px;
}

.our-service {
  font-size: 16px;
  letter-spacing: -0.67px;
  line-height: 19px;
}

// .input-actived {
//   .create-account-btn-group,
//   .btn-accept,
//   .btn-group-stp3,
//   .login-btn {
//     display: none !important;
//   }
// }

.continue-grp {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.step1-grp {
  margin-bottom: 0px !important;
}

.login-grp {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  // position: absolute;
  width: 100%;
  bottom: 5%;
}

.form-container-stp1 {
  height: 70%;
}

.form-control-stp3 {
  height: calc(100% - 250px) !important;
  min-height: calc(100% - 250px) !important;
}

.btn-group-stp3 {
  height: 230px !important;
  .update-user-grp {
    // position: fixed;
    bottom: 5%;
  }
}

.title-grp {
  .title {
    padding-top: 30px !important;
    padding-bottom: 50px !important;
    font-size: 16px;
    letter-spacing: -0.67px;
    line-height: 19px;
  }
}

.creat-account-btn-valid {
  position: absolute;
  z-index: 3;
  margin-left: 3px;
}

.info-link {
  margin-top: 8px;
  font-size: 8px !important;
}

// fix dot slider iphone x
@media only screen and (max-width: 380px) and (min-width: 375px) {
  .control-dots {
    position: absolute;
    left: 55% !important;
  }
}

// fix dot slider pixel 2
@media only screen and (max-width: 412px) and (min-width: 381px) {
  .control-dots {
    position: absolute;
    left: 120% !important;
  }
}

// fix dot slider pixel 2
@media only screen and (max-width: 600px) and (min-width: 413px) {
  .control-dots {
    position: absolute;
    left: 48% !important;
  }
}

.lock-container {
  display: flex;

  .lock-ico {
    margin-right: 9px;
    height: 18px;
    width: 15px;
  }
}

.see-our-proprietary {
  padding-bottom: 30px !important;
}

.update-account-container {
  padding-left: 0px;
  padding-right: 0px;

  .form-group {
    padding-left: 0px !important;
  }

  .form-group:nth-child(1),
  .form-group:nth-child(2),
  .form-group:nth-child(4),
  .form-group:nth-child(5),
  .form-group:nth-child(6),
  .form-group:nth-child(7) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.modal-skip .body-modal-skip {
  padding-bottom: 27px !important;
  min-height: calc(100vh - 80px) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.link-more-account-loader {
  margin-top: 40px;
}

.update-user-grp {
  padding-top: 50px;
}

.reward-summary-custom {
  display: flex;
  width: 100% !important;
  flex-direction: column;

  .your-rewards-summary-laptop {
    display: flex;
    .your-rewards-summary,
    .info-ico-reward {
      display: none;
    }
  }

  .your-rewards-summary-laptop-hide-mobile {
    display: none;
  }

  .user-profile {
    margin-top: 10px;
  }

  .your-reward-title {
    display: none !important;
  }

  .your-rewards-summary-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 23px;
    margin-bottom: 52px;

    .your-rewards-summary-container {
      display: flex;
      align-items: center;
      height: fit-content;
    }

    .info-ico-reward {
      margin-left: 5px !important;
    }

    .month-select {
      display: none;
    }
  }

  .btn-select {
    width: 45px !important;
  }

  .your-rewards-summary {
    font-size: 13px;
  }
}

.missed-summary-rectangle {
  margin-left: 25px;
}

.group-date-main {
  display: none;
}

.personalized-content {
  padding: 0px;
}

.spending-item {
  .next-ico-reward-grp {
    bottom: 30px !important;
  }
}

.personalized-item {
  .next-ico-reward-grp {
    bottom: 35px !important;
  }
}

.reward-content-summary {
  img {
    transform: translateX(-5%);
  }

  .reward-content-text {
    font-size: 16px;
    min-width: 120px !important;
  }
  // .img-gift {
  //   height: 90px;
  //   width: auto;
  // }
}

.image-no-stats {
  width: unset;
}

.modal-earning-mb {
  top: unset !important;
}

.title-get-earning {
  margin-top: 34px;
}

.modal-earning-mb-maximum {
  top: unset !important;
}

.custom-divider {
  margin-right: 0px !important;
  padding-left: 0px;
  padding-right: 0px;
}

.modal-earning-mb-maximum {
  top: 0% !important;
  .modal-earning-maximum {
    .modal-item {
      padding-top: 27px;
      padding-bottom: 27px;
      .title-get-earning {
        margin-top: 0px;
      }
    }
  }
}

.icon-container {
  min-width: 53px;
  margin-right: 13px !important;
}

.container-page {
  .reward-container {
    .reward-summary-custom.border-right {
      border-right: unset !important;
    }
  }
}

.by-clicking-on-cont {
  display: block !important;
  font-size: 12px;
  letter-spacing: -0.5px;
  line-height: 14px;
  color: #000000;

  .termAndPrivacy {
    color: #3f52c7;
    text-decoration: underline;
    cursor: pointer;
  }
}

.pt-40 {
  padding-top: 40px !important;
}

.modal-options-wed-mobile--title {
  margin-top: 30px;
  p {
    color: #000000;
    font-family: 'Karla';
    font-size: 24px;
    font-weight: 800;
    letter-spacing: -1.44px;
    line-height: 28px;
  }
  // p {
  //   &:last-child {
  //     font-size: 16px;
  //     letter-spacing: -0.5px;
  //     line-height: 19px;
  //   }
  // }
}

.modal-plaid-custom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-about-reward {
  height: auto !important;
}

.backgroundLanding {
  .section-landing-1 {
    margin-top: 24px;
    margin-bottom: 130px;
    &--contents {
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &--col {
      display: flex;
      justify-content: flex-start;
    }
    &--store-contents-desktop {
      display: none;
    }
    p {
      margin: 0 auto;
      margin-bottom: 40px;
    }
    &--col-phone {
      display: flex;
      justify-content: center;
    }
  }
  .section-landing-2 {
    &--col {
      display: flex;
      justify-content: center;
    }
    &--content-desktop {
      display: none;
    }
    p {
      //  margin-bottom: 40px;
      width: 212px;
    }
  }
  h1 {
    height: 80px;
    width: 320px;
    color: #000000;
    font-family: 'Sharp Grotesk Medium 25';
    font-size: 30px;
    letter-spacing: -0.6px;
    line-height: 40px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 16px;
  }
  p {
    height: 46px;
    width: 193px;
    color: #000000;
    font-family: Karla;
    font-size: 20px;
    letter-spacing: -0.63px;
    line-height: 23px;
    text-align: center;
  }

  &--phone {
    height: 398px;
    width: 183px;
  }
  &--store {
    height: 31px;
    width: 93px;
    &:first-child {
      margin-right: 11px;
    }
  }
  .mobile-section-1-p {
    width: 220px !important;
    height: fit-content !important;
  }
  .mobile-section-2-p {
    width: 255px !important;
    height: fit-content !important;
  }
}

.d-flex-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.re-captcha-container {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  height: 60px;
  width: 100%;
  position: relative;
  .grecaptcha-badge {
    position: relative !important;
    right: unset !important;
    bottom: unset !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.nav-group.navbar-landing {
  padding: 24px !important;
}
