$blue-color: #4b64ff;
$gradient-background: linear-gradient(155.27deg, #4b64ff 0%, #3c4db6 100%);
$small-font: 12px;
$normal-font: 20px;

.blue-color {
  color: $blue-color;
}

.btn-base {
  height: 55px;
  border-radius: 28px;
  background: $gradient-background;
  color: #ffffff;
}

.btn-base:focus {
  height: 55px;
  border-radius: 28px;
  background: $gradient-background !important;
  color: #ffffff !important;
}

.btn-signup {
  font-size: 20px;
  font-weight: 600;
}

.btn-login {
  color: #3f52c7;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.83px;
  line-height: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

.btn:focus {
  outline: 0 !important;
  box-shadow: none;
  color: #ffffff;
}

.btn:hover {
  outline: 0 !important;
  box-shadow: none;
  color: #ffffff;
}

.border-bottom {
  border-bottom: 1px solid #cccccc !important;
}

.no-border {
  border: none !important;
}

.close {
  display: none;
}

.m-atuo {
  margin: auto !important;
}

.form-control {
  border: none;
  padding-bottom: 0px;
  border-bottom: 1px solid #cccccc !important;
  font-size: 20px !important;
  border-radius: 0px !important;
  padding-left: 0px;
}

.form-control:focus {
  border: none;
  padding-bottom: 0px;
  outline: 0 !important;
  border-radius: 0px !important;
  box-shadow: none;
}

.small-font-size {
  font-size: $small-font;
}

.normal-font-size {
  font-size: $normal-font;
}

.lable-input {
  padding-left: 10px;
}

.modal-footer {
  justify-content: center;
}

.PhoneInputInput {
  border: none;
}

.PhoneInputInput:focus {
  border: none;
  outline: 0 !important;
  box-shadow: none;
}

.btn-text {
  background: none;
  text-decoration: underline;
  color: #3f52c7 !important;
}

.btn-text:focus {
  background: none;
  text-decoration: underline;
  color: #3f52c7 !important;
}

.btn-text:hover {
  background: none;
  text-decoration: underline;
  color: #3f52c7 !important;
}

.valid-text {
  color: #81000e;
}

.form-group {
  position: relative;
  .validation-img {
    position: absolute;
    right: 0;
    bottom: 34px;
  }

  .phone-validation {
    bottom: 45%;
  }
  .validation-ssn {
    position: absolute;
    right: 0;
    bottom: 60%;
  }
  .validation-date-img {
    position: absolute;
    right: 0;
    bottom: 60%;
  }
}
.otp-group {
  margin-right: 10px;
  padding-bottom: 10px;
  input {
    width: 100% !important;
    border: none;
    font-size: 30px;
    font-weight: 800;
    letter-spacing: -0.47px;
    line-height: 36px;
    font-size: 800;
  }
  input:focus {
    width: 100% !important;
    border: none;
    outline: 0 !important;
    box-shadow: none;
  }
}

.back-ico {
  font-size: 35px;
  // margin-top: -15px;
  margin-left: -5px;
}

.btn-back {
  background: none;
  border: none;
  padding: 20px 0px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.btn-continue {
  margin-top: 20px;
  width: 252px;
}

.btn-signup-modal {
  margin-top: 0px !important;
}

.number-input {
  width: 100% !important;
  border: none;
  border-bottom: 1px solid #cccccc !important;
  padding-left: 0px;
  font-size: 20px;
  padding: 0px;
}

.number-input:focus {
  width: 100% !important;
  border: none;
  outline: 0 !important;
  box-shadow: none;
  padding-left: 0px;
  font-size: 20px;
}

.container-navbar {
  padding-left: 3vw;
  padding-right: 3vw;
}

.login-nav {
  z-index: 2;
}

.btn-text:disabled {
  background: none;
  text-decoration: underline;
  color: rgb(125, 125, 235);
}

.content-continue {
  padding: 200px 60px;
  font-size: 20px;
  letter-spacing: -0.83px;
  line-height: 23px;
}

.blur-container {
  width: 100%;
  height: 100%;
  background-size: cover;
  position: absolute;
  // opacity: 0.8;
  // background: rgb(45, 45, 45);
  background: rgba(61, 61, 61, 0.85);
  z-index: 3;
  // box-shadow: inset 0 0 0 50vw rgba(146, 146, 146, 0.2);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.modal-item {
  // height: 688px;
  height: 720px;
  padding-left: 20px;
  padding-right: 20px;
}

.pt-100 {
  padding-top: 100px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.btn-close {
  border: none;
  background: none;
  position: absolute;
  // margin-top: 5px;
  z-index: 5000;
  right: 15px;
  top: 15px;
  padding: 0px;

  font-size: 28px;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.btn-close:focus {
  border: none;
  background: none;
  outline: 0 !important;
  box-shadow: none;
}

.text-right {
  text-align: right;
}

.welcome-title {
  margin-left: 0px;
  padding-top: 25px !important;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: -1px;
  line-height: 28px;
}

.text-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pl-0 {
  padding-left: 0px !important;
}

.loader-btn {
  padding-left: 20px;
}

.ssn-label {
  position: absolute;
  top: 3px;
  font-size: 20px;
  padding-bottom: 3px;
  border-bottom: 1px solid #cccccc !important;
  color: rgb(100, 100, 100);
}

.number-input-ssn {
  width: calc(100% - 63px) !important;
  border: none;
  border-bottom: 1px solid #cccccc !important;
  margin-left: 63px;
  font-size: 20px;
}

.number-input-ssn:focus {
  border: none;
  outline: 0 !important;
  box-shadow: none;
}

.logo {
  height: 22px;
  width: 116px;
}

label {
  letter-spacing: -0.5px;
  line-height: 14px;
}

.terms-conditions {
  margin-top: 20px;
  font-size: 12px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.btn-continue-signup {
  margin-top: 20px !important;
  width: 200px;
  height: 50px;
}

h6 {
  font-size: $normal-font;
}
